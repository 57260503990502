/* Global CSS */

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: "Roboto", sans-serif;
  -moz-osx-font-smoothing: "Roboto", sans-serif;
  font-style: normal;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
:root {
  --primary: DarkSlateGray;
  --heading-color: #282938;
  --bg-shade: #f5fcff;
  --github: #e62872;
  --darkblue: #1c1e53;
  --black: #000000;
  --white: #ffffff;
 
}
.btn {
  font-family: "Roboto";
  display: inline-block;
  padding: 14px 32px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border-radius: 35px;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  -ms-border-radius: 35px;
  -o-border-radius: 35px;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  -ms-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
}
.btn-outline-primary {
  color: white;
  background-color: rgb(80, 125, 124);
  border: 1px solid rgb(80, 125, 124);
}
.btn-outline-primary:hover {
  color: var(--white);
  background-color: rgb(45, 156, 130);
}
.btn-primary {
  color: var(--white);
   background-color: rgb(45, 156, 130);
  
}
.btn-primary:hover {
  color: rgb(78, 194, 174);
  background-color:rgb(49, 89, 88);
}
.btn-github {
  color: var(--white);
  background-color: var(--github);
  border: btn-primary;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.95);
  gap: 16px;
}
.btn-github:hover {
  color: var(--github);
  background-color: var(--white);
}
/* Global CSS Ends */
/* Main Headings */

/* Heading 1 */
h1 {
  font-size: 56px;
  font-weight: 700;
  line-height: 67px;
}
/* Heading 2 */
h2 {
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;
}
/* Heading 3 */
h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
}

/* Pragraphs text large medium small */
/* Body 1 */
.text-lg {
  color: var(--darkblue);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}
/* Body 2 */
.text-md {
  color: var(--darkblue);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
/* Body 3 */
.text-sm {
  color: var(--black);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

/* Section Titles */

.section--title {
  color: var(--heading-color);
  font-size: 21px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  display: flex;
  align-items: flex-start;
}
/* Portfolio, Testimonial & Contact Me */

.sub--title {
  color: var(--heading-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
/* Section Titles Ends */

/* Navbar Style Start */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 85.333px;
  background-color:rgb(62,104,104);
  color:white;
  box-shadow: 0px 5.333px 80px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
 } 
  
.circle-container{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px; 
  height: 70px;
  border-radius: 50%; /* Make it a circle */
  border:1px solid lightgray;
  box-shadow: 0 4px 8px rgba(0,0,0,.1);
  font-size:40px;
  font-weight:bold;
  margin-left:-30px;
  
  
}

.logosize{
  width: 70px; 
  height: 70px;

}


.navbar--items > ul {
  list-style: none;
  display: flex;
  align-items: flex-start;
  gap: 42.667px;
  text-decoration: none;
  padding-inline-start: 0;
}
.navbar--items ul > li > a {
  text-decoration: none;
}
/* Navbar Content */

.navbar--active-content {
   color:rgb(120, 228, 211);
   text-decoration: underline !important;
}
.navbar--link {
  text-decoration: none;
  
}

.navbar--link:hover {
  text-decoration: underline; /* Add underline on hover */
}

.logosize{
  width: 70px; 
  height: 70px;

}

.nav-name{
font-family: "Roboto", sans-serif;
color: white;
}
/* Navbar Styler Ends */




/*.homepage-section{
 padding: 112px 85px; 
 min-height:800px;
 background-image: url('../public/img/homebackground.jpg');
  background-size: cover;
  background-position: center; 
  background-repeat: no-repeat; 
  background-attachment: fixed; 
 
}*/

.homepage-section {
  position: relative;
  min-height:970px;
  display: flex;
  justify-content: flex-start; /* Center content horizontally */
  align-items: center;
 
   
}

.background-image {
  position: absolute;
  background-color:rgb(62,104,104);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the entire section */
  z-index: 1; /* Places the image behind other content */
}
.homeContent {
  position: relative;
  z-index: 2; 
  margin:0 85px 0 85px;
  padding:0 85px 0 85px; 
  display:flex;
  flex-direction:column;
  align-items: flex-start; 
  font-family: "Dosis", sans-serif;
  gap:10px;
  color:white;
 
  /* Places the content on top of the image */
}
.home_name{
font-size:55px;
 font-family: "Dosis", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600; /* Use the desired font weight */
  font-style: normal;
 
 
}
.homeContent p{
 font-size:30px;
 font-family: "Dosis", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500; /* Use the desired font weight */
  font-style: normal;
 
}
.media-icons{
 display:flex;
 padding-inline-start: 0;
 gap:20px;
 margin-top:0px;
 margin-bottom:0px;
}

.media-icons li{
  list-style: none;

}

.home-navbar--items ul{
 display:flex;
 flex-direction:row;
 gap:35px;
  padding-inline-start: 0;
}
.home-navbar--items ul li{
  list-style: none;
  font-size:20px;
}

.firstlink{
text-decoration: underline;
 color:rgb(120, 228, 211);
}

/* Hero Section Style */






/* My Portfolio Starts */
.portfolio--section {
margin-bottom:150px;
  display: flex;
  padding: 112px 85px;  
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
  
}
.portfolio--section--container {
  display: flex;
 
  gap: 10px;
  justify-content:center;
  align-items: stretch;
  flex:1;
}




.portfolio--section--card {
  display: flex;
  flex-direction: column;
   background-color:rgb(209, 231, 223);
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
  width:100%;
  
}
.portfolio--section--img{
  box-shadow: 0 0 32px 0  rgba(0, 0, 0, 0.15);
  height: 200px; 
}

.portfolio--section--img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}
.portfolio--section--img:hover > img {
  transform: scale(0.9);
}
.discriptionBoxSize{ 
  height: 200px;
}
.portfolio--section--card--content {
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
 
}
.portfolio--section--title {
  color: var(--heading-color);
  margin-bottom:20px;
}
.portfolio--link {
  text-decoration: none;
  display: flex;
  gap: 16px;
  border-bottom: 1.33px solid var(--primary);
  padding-bottom: 10.6px;
  font-weight: 600;
  
}


/* Portfolio Ends */

/*hero section*/
.hero--section {

  display: grid;
  height:100%;
  grid-template-columns: repeat(2, 1fr);
  padding: 133.333px 85.333px 133.333px;
  align-items: center;
  justify-content: space-between;
  gap: 60px;
 
  background-color:rgb(209, 231, 223);
}

.hero--title{
  color: var(--heading-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom:20px;
}


.hero--section--content-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;

}
.hero--section--content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 21.333px;
}

.hero--section--content--box > button {
  margin-top: 21.333px;
}
.hero--section--title {
  color: var(--heading-color);
  font-size: 74.667px;
  font-weight: 700;
  line-height: 90px;
  align-self: stretch;
  margin-bottom:20px;
}
.hero--section--title--color {
  color: var(--primary);
}
.hero--section-description {
  color: var(--darkblue);
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  margin-bottom:20px;
}
.hero--section--img {
  display: flex;
 justify-content: center;
 align-items: center;

}
.hero--section--img > img {
    max-width:100%; 
    height: auto;
    border-radius: 5px; 
    
}
/* Hero Section Style Ends */





/*
.resume-section{
  display: flex;
  background-color:lightblue;
   padding: 150px 85px;
  width: 100%;
  align-items: center;
  justify-content:space-between;

}
.resume--section--container{
  background-color: var(--bg-shade);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,.1);
  margin: 0 auto;
  max-width: 800px;
  padding: 1rem;
  width: 90%;

}
*/

.contact--section {
  display: flex;
  min-height:600px;
  padding: 133.333px 85.333px 133.333px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 64px;
  

}
.contact--form--container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: repeat(5, auto);
  row-gap: 22px;
   
}
.container {
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 32px;
  row-gap: 32px;
}
.contact--label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5.6px;
  flex: 1 0 0;
  width: 100%;
}
.contact--input {
  display: flex;
  font-family: "Roboto";
  padding: 12px;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1.333px solid var(--primary);
  background: var(--white);
}

/* Contact End */

/* Footer Start */
/* Footer Starts */
.footer--container {
  display: flex;
  padding: 96.667px 85.333px;
  flex-direction: column;
  align-items: flex-start;
  background-color:rgb(62,104,104);
  align-items: center;
  align-self: stretch;
  color:white;
}
.footer--link--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.footer--items > ul {
  list-style: none;
  display: flex;
  align-items: flex-start;
  gap: 42.667px;
  text-decoration: none;
}
.footer--items ul > li > a {
  text-decoration: none;
}
.footer--social--icon > ul {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  text-decoration: none;
}
.divider {
  margin: 106px 0 42.67px;
  height: 1.333px;
  width: 100%;
  background: rgba(40, 41, 56, 0.55);
}
.footer--content--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.footer--content {
  color: white;
  font-size: 18.667px;
  font-weight: 400;
  line-height: 28px;
}

.footer--img{
 width:30px;
 height:30px;
 background-color:white;
 border-radius: 50%; 

 background-size: 50% !important;
}

.footer--img:hover{

 opacity: 0.3;
 filter: alpha(opacity=30);

}
.navbar--content:hover{
 font-size:20px;
 color:darkgreen;
 text-decoration: underline;
}

/* Responsive Screens */


@media only screen and (max-width: 1200px) {
   .hero--section {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap:wrap;
    padding: 50.333px 30.333px 50.333px;
  }
  .hero--section--title{
    text-align: center;
  }
   .hero--section-description{
  text-align:start;
  }
 .hero--section--content,
  .hero--section--content--box {
    align-items: center;
   
  }
  .hero--section--content--box {
     gap: 10px;
    
  }
   .hero--section--title{
   text-align:start;
    font-size: 35px;
    line-height: 40px;
  }
  /**********************/
 .portfolio--section {
  padding: 50.333px 30.333px 50.333px;
  gap: 80px;
}
 
  
.portfolio--section--img > img {
  width: 100%;
   height:300px;
}

.portfolio--section--container{
   display: grid;
   grid-template-rows: 1fr 1fr;
   grid-template-columns:1fr 1fr;
   
  }
 .discriptionBoxSize{ 
  height: 150px;
}

 .portfolio--container{
   align-items: center;
 }
 
  
  
  .portfolio--section--img {
  border-radius: 8px;
  width: 100%;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3);
  height:300px;
}


  .btn-outline-primary {
    display: none;
  }
 
 
  .footer--content {
    text-align: center;
  }
 
 
  .footer--link--container,
  .footer--items > ul,
  .footer--content--container {
    
 
   display: flex;
    flex-direction: column;
  
  }

 
  .footer--container {
    gap: 20px;
   padding: 50.333px 30.333px 50.333px;
  }
 
 
 
 
  .sections--heading {
    font-size: 35px;
    line-height: 40px;
  }
  

  
  .footer--social--icon > ul,
  .footer--items > ul {
    padding: 0;
    align-items: center;
  }
  .divider {
    margin: 20px;
  }


/* nav */

.circle-container{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px; /* Set the width and height to create a circular shape */
  height: 50px;
  border-radius: 50%; /* Make it a circle */
  border:1px solid lightgray;
  box-shadow: 0 4px 8px rgba(0,0,0,.1);
  font-size:30px;
  font-weight:bold; 
  
}

.logosize{
  width: 50px; 
  height: 50px;

}
/*.nav__hamburger {
  display: none;
  width: 2.075rem;
  height: 1.313rem;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  top: 25px;
  right: 65px;
}

.nav__hamburger__line {
  display: block;
  height: 0.188rem;
  width: 100%;
  background-color: #000000;
  border-radius: 0.625rem;
  transition: all ease-in-out 0.2s;
}

  .nav__hamburger {
    display: flex;
    z-index: 200;
  }
  .navbar--items {
    display: flex;
  }
  .navbar--items {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255);
    top: -20rem;
    left: 0;
    width: 100%;
    transition: all ease-in-out 0.4s;
  }
  .navbar--items ul {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 3rem 0 0.6rem;
    z-index: -100;
  }
  .navbar--items ul li {
    text-align: center;
  }
  .navbar--items ul li a {
    padding: 0.5rem;
  }
  .navbar--items {
    z-index: -1000;
  }
  .navbar--items.active {
    top: 30px;
  }
  .nav__hamburger.active :nth-child(1) {
    transform: rotate(45deg) translate(0.45rem, 0.1875rem);
  }

  .nav__hamburger.active :nth-child(2) {
    opacity: 0;
  }

  .nav__hamburger.active :nth-child(3) {
    transform: rotate(-45deg) translate(0.45rem, -0.1875rem);
  }
  */
  .resume-section{
  padding: 50.333px 30.333px 0.333px;
 }
 
 
.contact--section {
 
  padding-top:50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 14px;
  padding-left:10px;
  padding-right:10px;
  
}
.contact--form--container {
  
  width: 48%;
  row-gap: 22px;
}
.container {
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 22px;
  row-gap: 22px;
}

.contact--input {
  display: flex;
  font-family: "Roboto";
  padding: 16px;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1.333px solid var(--primary);
  background: var(--white);
  
}

 
}
@media screen and (max-width: 1024px) {
 
 .container {
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 32px;
  row-gap: 22px;
}
 
 .contact--form--container {
  
  width:60%;
  row-gap: 32px;
}
.contact--input {
  display: flex;
  font-family: "Roboto";
  padding: 10px;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1.333px solid var(--primary);
  background: var(--white);
  
}

}
@media screen and (max-width: 800px) {
 
.contact--form--container { 
  width: 70%;  
}
.nav__hamburger {
 display:absolute;
  right: 30px;
}
}


@media screen and (max-width: 760px) {
 .navbar {
  
  padding: 13px 30px;
 
 } 
  


.logosize{
  width: 70px; 
  height: 70px;

}


.navbar--items > ul {
  
 
  gap: 16.667px;
  
}

.contact--section {
  margin-left:0px;
  margin-right:0px;
  }

.container {
  display: grid;
  grid-template-columns: repeat(1, auto);
  
}
.contact--form--container {
  
  width: 100%;
  
}
.portfolio--section--container{
   display: grid;
   grid-template-rows: 1fr;
   grid-template-columns:1fr;
   
  }
.discriptionBoxSize{ 
  height: 120px;
}

}
@media only screen and (max-width: 500px) {



.homeContent {
  position: relative;
  z-index: 2; 
  margin:0 0 0 0;
  padding:0 30px 0 30px; 
  
 
  /* Places the content on top of the image */
}
.home_name{
font-size:35px;
 
 
 
}
.homeContent p{
 font-size:25px;
 font-family: "Dosis", sans-serif;
 
}
.contact--form--container {
  
  width:100%;
  row-gap: 12px;
}
.contact--input {
  display: flex;
  font-family: "Roboto";
  padding: 5px;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1.333px solid var(--primary);
  background: var(--white);
  
}
.contact--section{
  padding:15px;
 
 }

 .discriptionBoxSize{
  width: 100%; /* Set a fixed width */
  height: 150px;

}
 
.container {
  display: grid;
  grid-template-columns: repeat(1, auto);
  
}
.portfolio--section {
  margin-bottom:15px;
}
.portfolio--section--img {
  border-radius: 8px;
  width: 100%;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3);
  height:300px;
}
.portfolio--section--img > img {
  width: 100%;
   height:300px;
}

}
@media screen and (max-width: 400px) {
 
 
 .discriptionBoxSize{
  width: 100%; /* Set a fixed width */
  height: 180px;

}
.portfolio--section--img {
  border-radius: 8px;
  width: 100%;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3);
  height:200px;
}
.portfolio--section--img > img {
  width: 100%;
   height:200px;
}

}

@media screen and (max-width: 380px) {
 
 .discriptionBoxSize{
  width: 100%; /* Set a fixed width */
  height: 200px;

}

}

/* Responsive Screens Ends*/
